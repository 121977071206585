export default [
  {
    path: "/auth/login",
    getComponent: (nextState, cb) => {
      import("./Auth/Login").then((module) => {
        cb(null, module.default);
      });
    },
    auth: true,
  },
  {
    path: "/auth/passrest",
    getComponent: (nextState, cb) => {
      import("./Auth/ResetPassword").then((module) => {
        cb(null, module.default);
      });
    },
    auth: true,
  },
  {
    path: "/auth/signup",
    getComponent: (nextState, cb) => {
      import("./Auth/SignUp").then((module) => {
        cb(null, module.default);
      });
    },
    auth: true,
  },
  {
    path: "/auth/confirmed",
    getComponent: (nextState, cb) => {
      import("./Auth/Confirmed").then((module) => {
        cb(null, module.default);
      });
    },
    auth: true,
  },
  {
    path: "/auth/forgot",
    getComponent: (nextState, cb) => {
      import("./Auth/Forgot").then((module) => {
        cb(null, module.default);
      });
    },
    auth: true,
  },
  {
    path: "/stores",
    getComponent: (nextState, cb) => {
      import("./Home").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/create-new-workspace",
    getComponent: (nextState, cb) => {
      import("./CreateFreeProject").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/shopifyUpgradePlan",
    getComponent: (nextState, cb) => {
      import("./App/UpgradePlanPopUp").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/newcampaigns",
    getComponent: (nextState, cb) => {
      import("./App/NewCampaigns").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/subscribers",
    getComponent: (nextState, cb) => {
      import("./App/Subscribers").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/home",
    getComponent: (nextState, cb) => {
      import("./App/HomePage").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/listWebPushCampaigns",
    getComponent: (nextState, cb) => {
      import("./App/ListWebPushs/ListWebPushs").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/listSMSCampaigns",
    getComponent: (nextState, cb) => {
      import("./App/ListSMSCampaigns").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/settings",
    getComponent: (nextState, cb) => {
      import("./App/NewSettings/NewSettings").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/products",
    getComponent: (nextState, cb) => {
      import("./App/Products/ListProducts").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/ApiAndSdk",
    getComponent: (nextState, cb) => {
      import("./App/Setting/components/ApiAndSdk").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/integrations",
    getComponent: (nextState, cb) => {
      import("./App/Setting/components/ExternalIntegrations").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/subscriptions",
    getComponent: (nextState, cb) => {
      import("./App/Setting/components/Subscriptions").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/operations",
    getComponent: (nextState, cb) => {
      import("./App/Setting/components/Operations").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/projectinfo",
    getComponent: (nextState, cb) => {
      import("./App/Setting/components/ProjectInfo").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/setting/:name",
    getComponent: (nextState, cb) => {
      import("./App/Setting/components/Edit").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/user/sub-users",
    getComponent: (nextState, cb) => {
      import("./App/SubUsers").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/user/:section",
    getComponent: (nextState, cb) => {
      import("./App/UserProfile").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/shortLinks/:smartlinkId/:action",
    getComponent: (nextState, cb) => {
      import("./App/SmartDeeplinks/CreateNewSmartLink").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/shortLinks/new",
    getComponent: (nextState, cb) => {
      import("./App/SmartDeeplinks/CreateNewSmartLink").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/shortLinks/upload",
    getComponent: (nextState, cb) => {
      import("./App/SmartDeeplinks/UploadBulkSDL").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/shortLinks",
    getComponent: (nextState, cb) => {
      import("./App/SmartDeeplinks/ListSmartDeepLinks").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/newlandingpages/:landingPageId/analytics",
    getComponent: (nextState, cb) => {
      import("./App/NewMLPAnalytics").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/push/web/new",
    getComponent: (nextState, cb) => {
      import("./App/Push/CreateWebPush").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/sms/new",
    getComponent: (nextState, cb) => {
      import("./App/CreateSMSCampaign").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/smsTargetLists",
    getComponent: (nextState, cb) => {
      import("./App/Push/ListTargetList").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/smsTargetLists/upload",
    getComponent: (nextState, cb) => {
      import("./App/Push/UploadTargetList").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/microPages/:landingPageId/analytics",
    getComponent: (nextState, cb) => {
      import("./App/MicroPages/MicroPageAnalytics").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/microPages",
    getComponent: (nextState, cb) => {
      import("./App/MicroPages/ListMicroPages").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/microPages/new",
    getComponent: (nextState, cb) => {
      import("./App/MicroPages/CreateMicroPage").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/microPages/:landingPageId/:action",
    getComponent: (nextState, cb) => {
      import("./App/MicroPages/CreateMicroPage").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/whatsapp/new",
    getComponent: (nextState, cb) => {
      import("./App/CreateWhatsApp/CreateWhatsApp").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/whatsapp/list",
    getComponent: (nextState, cb) => {
      import("./App/ListWhatsAppCampaigns/ListWhatsAppCampaigns").then(
        (module) => {
          cb(null, module.default);
        }
      );
    },
    private: true,
  },
  {
    path: "/app/:subdomain/whatsappLite/list",
    getComponent: (nextState, cb) => {
      import("./App/ListWhatsAppLite/ListWhatsAppLite").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/unofficial-whatsapp/new",
    getComponent: (nextState, cb) => {
      import("./App/Push/CreateUnofficialWhatsApp").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  // {
  //   path: "/app/:subdomain/unofficial-whatsapp/new",
  //   getComponent: (nextState, cb) => {
  //     require.ensure(
  //       [],
  //       (require) => {
  //         cb(null, require("./App/Push/CreateUnofficialWhatsApp").default);
  //       },
  //       "create-unofficial-whatsapp"
  //     );
  //   },
  //   private: true,
  // },
  {
    path: "/app/:subdomain/crm",
    getComponent: (nextState, cb) => {
      import("./App/CRM").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/segments",
    getComponent: (nextState, cb) => {
      import("./App/Segments").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/segments/:segmentId",
    getComponent: (nextState, cb) => {
      import("./App/SegmentDetails").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/notificationLogs",
    getComponent: (nextState, cb) => {
      import("./App/NotificationLogs").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/abandonedCart",
    getComponent: (nextState, cb) => {
      import("./App/AbandonedCart").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/email/new",
    getComponent: (nextState, cb) => {
      import("./App/CreateEmail").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/email/list",
    getComponent: (nextState, cb) => {
      import("./App/ListEmailCampaigns").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/templates",
    getComponent: (nextState, cb) => {
      import("./App/Templates/List").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/templates/new",
    getComponent: (nextState, cb) => {
      import("./App/Templates/Create").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/templates/:templateId",
    getComponent: (nextState, cb) => {
      import("./App/Templates/Create").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/automator",
    getComponent: (nextState, cb) => {
      import("./App/Automator/List").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/automator/:automatorId/analytics",
    getComponent: (nextState, cb) => {
      import("./App/Automator/AutomatorAnalytics").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/automator/:automatorId",
    getComponent: (nextState, cb) => {
      import("./App/Automator/Create").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "/app/:subdomain/purchases",
    getComponent: (nextState, cb) => {
      import("./App/Purchases").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
  {
    path: "*",
    getComponent: (nextState, cb) => {
      import("./NotFound").then((module) => {
        cb(null, module.default);
      });
    },
    private: true,
  },
];
