import ENDPOINTS from "consts/api";
import api from "modules/api";

export default class Config {
  static getNotifyConfig = (appId) =>
    api("get", ENDPOINTS.NOTIFY.CONFIG(appId));

  static setNotifyConfig = (appId, data) =>
    api("put", ENDPOINTS.NOTIFY.CONFIG(appId), data);

  static getNotifyLogs = (appId, page, start, end) =>
    api("get", ENDPOINTS.NOTIFY.LOGS(appId, page, start, end));

  static sendPush = (data) => api("post", ENDPOINTS.SEND_LATER.NOTIFY(), data);

  static deleteUser = (data) =>
    api("post", ENDPOINTS.SEND_LATER.DELETE_USER(), data, false, false);
}
