import axios from "axios";

import { config } from "consts/api";
import { userData } from "modules/user";

const { API_HOST } = config;

export const importAllProducts = (shopifySubdomain, suitId) => {
  const user = userData();

  return axios.post(
    `${API_HOST}/shopify/smartlinks/${suitId}/all_products`,
    null,
    {
      headers: {
        authToken: user.auth_token,
        "x-shopify-shop-domain": shopifySubdomain,
      },
    }
  );
};

export const importAllZidProducts = (suitId) => {
  return axios.post(`${API_HOST}/zid/smartlinks/${suitId}/all_products`);
};

export const importAllSallaProducts = (suitId) => {
  return axios.post(`${API_HOST}/salla/smartlinks/${suitId}/all_products`);
};
