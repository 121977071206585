import { Modal } from "antd";
import moment from "moment";
import React from "react";
import { I18n } from "react-redux-i18n";
import classes from "./ViewPlanFeatures.scss";

const dateFormat = "DD MMM YY";

export default function ViewPlanFeatures({
  suit,
  display,
  onClose,
  onConfirm,
  disabled,
}) {
  let product = suit.products_usage;

  return (
    <Modal
      title={
        <div>
          {I18n.t("SettingsPage_subscriptions_modal_title")}
          <span>{I18n.t("SettingsPage_subscriptions_modal_title2")}</span>
        </div>
      }
      visible={display}
      onOk={onConfirm}
      onCancel={onClose}
      footer={
        <div className={classes.modalFooter}>
          <button onClick={onClose} className={classes.modalCancel}>
            {I18n.t("close")}
          </button>
          <button
            disabled={disabled}
            onClick={onConfirm}
            className={classes.modalConfirm}
          >
            {I18n.t("upgrade")}
          </button>
        </div>
      }
    >
      <div className={classes.description}>
        {Object.keys(product).map((key, index) => {
          if (key == "date_range") return;
          return (
            <div className={classes.row} key={index}>
              <span>{product[key].label}</span>
              <span>
                {product[key].usage || product[key].usage == 0
                  ? product[key].usage
                  : ""}
                {product[key].limit ? " / " + product[key].limit : ""}
                <span className={classes.hint}>
                  {product[key].second_label ? product[key].second_label : ""}
                </span>
              </span>
            </div>
          );
        })}
        {product.date_range && (
          <div className={classes.dateRange}>
            <span>
              {product &&
                `${I18n.t(
                  "SettingsPage_subscriptions_modal_UpgradeNote"
                )} ${moment
                  .utc(product.date_range.start)
                  .local()
                  .format(dateFormat)} ${I18n.t(
                  "SettingsPage_subscriptions_modal_until"
                )} ${moment
                  .utc(product.date_range.end)
                  .local()
                  .format(dateFormat)}.`}
            </span>
          </div>
        )}
      </div>
    </Modal>
  );
}
