// --------------------------------------------------------
// Development configurations
// --------------------------------------------------------

import { GET_NOTIFY_LOGS } from "../reducers/config";

const PROD_MOD = true;

const freePlanId = "6506f8721e7fbf0025a26149";
const INFRA_TYPE = "shared";

const development = {
  DOMAIN: "shrinkit.me",
  SENTRY_URL:
    "https://deab84db20f94b4cbe8c762d5531d80f@o66960.ingest.sentry.io/5262628",
  STRIPE_APIKEY:
    "pk_live_51IQZB8LSit1uK5ytP91QCYcbr8Th4kYJdTTu4gHOlsW6QMBN0Uazey0NVpIduQYDi6EhO0nU5uFT3JrkitEuesQr00DKmp0KyK",
  StripSecretKey:
    "Bearer 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",
  STRIPE_INVOICES_KEY:
    "Bearer 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",
  userDroppedCreateNewProjectProjectId: "5918826f27af4d6722e820e0",
  userDroppedCreateNewProjectApiKey:
    "397bef636fd4e44c212916493fab762268e74527bef732183c9b031011d3a5b4",
  SHOPIFY_API: "https://preprodshopifydev.instabackend.io/api/",
  appBackend:
    "https://preprodappbackend.appgain.io/610aa03b8d52dc0051713d3c/stgshrinkitapp",
  coreAppId: "stgshrinkitapp",
  servUrlInvocations: `https://notify.shrinkit.me`,
};

const extraDevelopment = {
  API_HOST: `https://preprodapi.${development.DOMAIN}`,
  AUTH: `https://api.${development.DOMAIN}/google/login`,
  NOTIFY_HOST: `https://preprodnotify.${development.DOMAIN}`,
  SEND_LATER_HOST: `https://preprodsechulder.shrinkit.me`,
  projectId: development.userDroppedCreateNewProjectProjectId,
  ZID_AUTH: `https://api.${development.DOMAIN}/zid/login`,
  AUTOMATOR_HOST: `https://preprodautomator.${development.DOMAIN}`,
};

extraDevelopment.MOBILE_LANDING = `${extraDevelopment.API_HOST}/ml`;
extraDevelopment.MOBILE_LANDING_PREVIEW = `${extraDevelopment.MOBILE_LANDING}/preview`;

Object.assign(development, extraDevelopment);

const production = {
  DOMAIN: "shrinkit.me",
  SENTRY_URL:
    "https://deab84db20f94b4cbe8c762d5531d80f@o66960.ingest.sentry.io/5262628",
  STRIPE_APIKEY:
    "pk_live_51IQZB8LSit1uK5ytP91QCYcbr8Th4kYJdTTu4gHOlsW6QMBN0Uazey0NVpIduQYDi6EhO0nU5uFT3JrkitEuesQr00DKmp0KyK",
  StripSecretKey:
    "Bearer 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",
  STRIPE_INVOICES_KEY:
    "Bearer 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",
  userDroppedCreateNewProjectProjectId: "5918826f27af4d6722e820e0",
  userDroppedCreateNewProjectApiKey:
    "397bef636fd4e44c212916493fab762268e74527bef732183c9b031011d3a5b4",
  SHOPIFY_API: "https://shrinkitshopf.instabackend.io/api/",
  appBackend: "https://appbackend.appgain.io/5e1711d536c848000b7a6dac/shrnkt",
  coreAppId: "shrnkt",
  SHOPIFY_API_DOESNT_HAVE_APP_IN_URL: "https://shrinkitshopf.instabackend.io",
  servUrlInvocations: `https://notify.shrinkit.me`,
};

const devops = {
  DOMAIN: "shrinkit.me",
  SENTRY_URL:
    "https://deab84db20f94b4cbe8c762d5531d80f@o66960.ingest.sentry.io/5262628",
  STRIPE_APIKEY:
    "pk_live_51IQZB8LSit1uK5ytP91QCYcbr8Th4kYJdTTu4gHOlsW6QMBN0Uazey0NVpIduQYDi6EhO0nU5uFT3JrkitEuesQr00DKmp0KyK",
  StripSecretKey:
    "Bearer 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",
  STRIPE_INVOICES_KEY:
    "Bearer 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",
  userDroppedCreateNewProjectProjectId: "5918826f27af4d6722e820e0",
  userDroppedCreateNewProjectApiKey:
    "397bef636fd4e44c212916493fab762268e74527bef732183c9b031011d3a5b4",
  SHOPIFY_API: "https://devopsshrinkitshopf.instabackend.io/api/",
  appBackend: "https://appbackend.appgain.io/5e1711d536c848000b7a6dac/shrnkt",
  coreAppId: "shrnkt",
  servUrlInvocations: `https://devopsnotify.shrinkit.me`,
};

const extraProduction = {
  API_HOST: `https://api.${production.DOMAIN}`,
  AUTH: `https://api.${production.DOMAIN}/google/login`,
  NOTIFY_HOST: `https://notify.${production.DOMAIN}`,
  SEND_LATER_HOST: `https://sechulder.shrinkit.me`,
  projectId: production.userDroppedCreateNewProjectProjectId,
  ZID_AUTH: `https://api.${production.DOMAIN}/zid/login`,
  AUTOMATOR_HOST: `https://automator.${production.DOMAIN}`,
  ADMIN_SERVER: "https://adminapi.shrinkit.me",
};

extraProduction.MOBILE_LANDING = `${extraProduction.API_HOST}/ml`;
extraProduction.MOBILE_LANDING_PREVIEW = `${extraProduction.MOBILE_LANDING}/preview`;

Object.assign(production, extraProduction);

const extraDevops = {
  API_HOST: `https://devopsapi.${production.DOMAIN}`,
  AUTH: `https://devopsapi.${production.DOMAIN}/google/login`,
  NOTIFY_HOST: `https://devopsnotify.${production.DOMAIN}`,
  SEND_LATER_HOST: `https://sechulder.shrinkit.me`,
  projectId: production.userDroppedCreateNewProjectProjectId,
  AUTOMATOR_HOST: `https://devopsautomator.${production.DOMAIN}`,
  ADMIN_SERVER: "https://devopsadminapi.shrinkit.me",
};

extraDevops.MOBILE_LANDING = `${extraDevops.API_HOST}/ml`;
extraDevops.MOBILE_LANDING_PREVIEW = `${extraDevops.MOBILE_LANDING}/preview`;

Object.assign(devops, extraDevops);

const initConfig = {
  freePlanId,
  INFRA_TYPE,
  PROD_MOD,
  CLIENT_ID: "94e28f154d385e1",
  // ...(PROD_MOD ? production : development),
  ...(PROD_MOD ? production : development),

  AUTH_TOKEN: true,

  SHOPIFY_SHRINKIT_APP: "https://apps.shopify.com/shrinkit-app",
};

export const config = Object.freeze({ ...initConfig });

// --------------------------------------------------------
// API end points
// --------------------------------------------------------

const {
  API_HOST,
  SHOPIFY_API,
  SEND_LATER_HOST,
  NOTIFY_HOST,
  appBackend,
  AUTOMATOR_HOST,
  ADMIN_SERVER,
  servURLInvocations,
} = config;

const API_ENDPOINTS = {
  freePlanId,
  INFRA_TYPE,
  USER: {
    LOGIN: () => `${API_HOST}/login`,

    DELETEUSER: () => `${API_HOST}/user`,

    REGISTER: () => `${API_HOST}/register`,

    FORGOT_PASSWORD: () => `${API_HOST}/passrecovery`,

    RESETPASSWORD: (token) => `${API_HOST}/reset_password?token=${token}`,

    RESEND_CONFIRMATION: (email) => `${API_HOST}/resend-link/${email}`,

    APPS: () => `${API_HOST}/apps`,

    USER_LINK: () => `${API_HOST}/user`,

    DeleteDemoData: (suitId) => `${API_HOST}/delete_dummy_data/${suitId}`,

    APP: (appId) => `${API_HOST}/apps/${appId}`,

    GENERATE: (AppId) => `${API_HOST}/apps/${AppId}`,

    addON: (suitId) => `${API_HOST}/${suitId}/buyAddon`,

    MediaLibrary: (suitId) => `${API_HOST}/${suitId}/list_media_files`,

    DeleteMedia: (suitId) => `${API_HOST}/${suitId}/delete_media_files`,

    FillDemoData: (suitId) => `${API_HOST}/create_dummy_data/${suitId}`,

    checkSubscribed: (serverURL, suitId, apiKey) =>
      `${serverURL}/functions/checkSubscribed?projectId=${suitId}&apiKey=${apiKey}`,
    getAppCurrency: (serverURL, suitId, apiKey) =>
      `${serverURL}/functions/getAppCurrency?projectId=${suitId}&apiKey=${apiKey}`,
    upgradePlan: (suit_id, plan_id) => `${API_HOST}/apps/${suit_id}/${plan_id}`,
    SubUser: () => `${API_HOST}/custom_user`,
  },
  STRIPE: {
    TOKEN: (userid) => `${API_HOST}/stripeToken/${userid}`,
    StripeInfo: (stripeCustomerId) =>
      `https://api.stripe.com/v1/customers/${stripeCustomerId}/sources`,
    INVOICES: () => "https://api.stripe.com/v1/invoices",
  },
  SHOPIFY_SUBSCRIPE: {
    shopifySubscripeUrl: () => {
      const shopifyUrl = SHOPIFY_API.replace("/api", "");
      return `${shopifyUrl}/subscription`;
    },
  },
  USER_LINK: () => "/user",
  APP: (id) => `/apps/${id}`,
  PRODUCTS: {
    getProducts: () => `${SHOPIFY_API}products`,

    getProductsAfterCursor: (cursor) =>
      `${SHOPIFY_API}products?cursor=${cursor}`,

    getProductsByName: (name) => `${SHOPIFY_API}products?title=${name}`,

    createSDLProduct: () => `${SHOPIFY_API}smartlinks`,
  },
  SMARTLINKS: {
    ALL: (appId) => `${API_HOST}/apps/${appId}/smartlinks`,
    getSuitSmartlinks: (suitId, data, page) => {
      let url = `${API_HOST}/apps/${suitId}/smartlinks`;
      if (data) {
        const filter = Object.keys(data).map((key) => `${key}=${data[key]}`);
        url += `?${filter.join("&")}`;
      }
      if (page) {
        url += `&page=${page}`;
      }
      return url;
    },
    SINGLE: (appId, smartlinkId) =>
      `${API_HOST}/apps/${appId}/smartlinks/${smartlinkId}`,

    BULK: (appId) => `${API_HOST}/apps/${appId}/smartlinks/bulk`,

    ANALYTICS: (appId, smartlinkId) =>
      `${API_HOST}/smartlinks/${appId}/stats/summary/${smartlinkId}`,

    UPLOAD: (suitId, email) =>
      `${API_HOST}/apps/${suitId}/smartlinksbulk?email=${email}`,

    STASTICS: (appId) =>
      `${API_HOST}/apps/${appId}/smartlinks?statssummary=true`,

    CHART_DATA: (appId, smartlinkId, filter) =>
      `${API_HOST}/apps/${appId}/smartlinks/${smartlinkId}/stats/chartdata${filter}`,

    HITSLOGS: (appId, smartlinkId) =>
      `${API_HOST}/apps/${appId}/smartlinks/${smartlinkId}/stats/hitslogs`,

    SEARCHSMARTLINKS: (appId, nameVal) =>
      `${API_HOST}/apps/${appId}/smartlinks?name=${nameVal}`,

    topShortLinks: (suitId) => `${API_HOST}/apps/${suitId}/topTenSmartLinks?`,
  },
  LANDINGPAGES: {
    ALL: (appId) => `${API_HOST}/apps/${appId}/landingpages`,
    getSuitLandingPages: (suitId, apiKey, data, page) => {
      let url = `${API_HOST}/apps/${suitId}/landingpages`;
      if (data) {
        const filter = Object.keys(data).map((key) => `${key}=${data[key]}`);
        url += `?${filter.join("&")}`;
      }
      if (page) {
        url += `&page=${page}`;
      }
      return url;
    },

    SINGLE: (appId, landingpageId) =>
      `${API_HOST}/apps/${appId}/landingpages/${landingpageId}`,

    BULK: (appId) => `${API_HOST}/apps/${appId}/landingpages/bulk`,

    STASTICS: (appId) =>
      `${API_HOST}/apps/${appId}/landingpages?statssummary=true`,

    SEARCH: (appId, nameVal) =>
      `${API_HOST}/apps/${appId}/landingpages?name=${nameVal}`,

    ANALYTICS: (appId, landingpageId) =>
      `${API_HOST}/landingpages/${appId}/stats/summary/${landingpageId}`,
  },
  TEMPLATES: {
    ALL: (appId) => `${NOTIFY_HOST}/${appId}/templates`,

    SINGLE: (appId, templateId) =>
      `${NOTIFY_HOST}/${appId}/templates/${templateId}`,
  },

  PUSH: {
    getSuitEmails: (suitId, start, end, page) =>
      `${NOTIFY_HOST}/${suitId}/campaigns?type=email${
        start && end ? `&period=day&start=${start}&end=${end}` : ""
      }${page ? `&page=${page}` : ""}`,
  },

  PUSHSTATICTICS: (appId) => `${NOTIFY_HOST}/${appId}/summary`,
  WEBPUSHSTATICTICS: (appId) => `${NOTIFY_HOST}/${appId}/summary/webPush`,
  LISTPUSH: (appId) => `${NOTIFY_HOST}/${appId}/campaigns`,
  getSuitAppPushs: (suitId, apiKey, data) => {
    if (data.name) {
      return `${NOTIFY_HOST}/${suitId}/campaigns?type=appPush&period=day&start=${data.start}&end=${data.end}&name=${data.name}`;
    } else {
      return `${NOTIFY_HOST}/${suitId}/campaigns?type=appPush&period=day&start=${data.start}&end=${data.end}`;
    }
  },
  PUSH_DELETE_BULK: (appId) => `${NOTIFY_HOST}/${appId}/campaigns/bulk`,
  getSuitWebPushs: (suitId, data) => {
    if (data) {
      return `${NOTIFY_HOST}/${suitId}/campaigns?type=webPush&period=day&start=${data.start}&end=${data.end}`;
    }
  },
  getSuitSMS: (suitId, data) => {
    if (data) {
      return `${NOTIFY_HOST}/${suitId}/campaigns?type=SMS&period=day&start=${data.start}&end=${data.end}`;
    }
  },
  DELETEPUSH: (appId, campaignId) =>
    `${NOTIFY_HOST}/${appId}/campaigns/${campaignId}`,
  WEBPUSHSUBSCRIBERS: (appId) =>
    `${NOTIFY_HOST}/notification/${appId}/stats/webpush`,
  Campaign_ANALYTICS: (appId, campaignId) =>
    `${NOTIFY_HOST}/campaigns/${appId}/stats/summary/${campaignId}`,

  TARGET_LIST: {
    BULK: (appId) => `${NOTIFY_HOST}/${appId}/SMStargetlist/bulk`,

    ALL: (appId) => `${NOTIFY_HOST}/${appId}/SMStargetlist`,

    SINGLE: (appId, targetListName) =>
      `${NOTIFY_HOST}/${appId}/SMStargetlist/${targetListName}`,

    DOWNLOAD: (appId, targetListName) =>
      `${NOTIFY_HOST}/${appId}/SMStargetlist/${targetListName}/download`,
  },
  // search
  SEARCHPUSH: (appId, nameVal) =>
    `${NOTIFY_HOST}/${appId}/campaigns?name=${nameVal}`,
  PLANS: {
    ALL: () => `${API_HOST}/plans`,
  },

  DEEPLINKS: {
    ALL: (Serverkey) => `${Serverkey}/classes/DeepLinks`,
    getSuitDeepLinks: (serv) => {
      let url = `${serv}/classes/DeepLinks`;

      return url;
    },
    SINGLE: (Serverkey, DeepLinkId) =>
      `${Serverkey}/classes/DeepLinks/${DeepLinkId}`,
  },
  NOTIFY: {
    CONFIG: (appId) => `${NOTIFY_HOST}/${appId}/config`,

    LOGS: (appId, page, start, end) =>
      `${NOTIFY_HOST}/${appId}/logs/all?page=${page}&start=${start}&end=${end}`,
  },
  SEND_LATER: {
    NOTIFY: () => `${SEND_LATER_HOST}/notifyjob`,
    DELETE_USER: () => `${SEND_LATER_HOST}/create/job`,
  },
  MOBILE_ATTRIBUTES: {
    getAttributes: (suitId) => `${API_HOST}/mobile_attributes/${suitId}`,
    listSmartLinks: (suitId) => `${API_HOST}/apps/${suitId}/smartlinks`,
    getCardsData: (suitId, start, end, filter, value) => {
      return !filter
        ? `${API_HOST}/mobile_attributes_counts/${suitId}?start=${start}&end=${end}`
        : `${API_HOST}/mobile_attributes_counts/${suitId}?start=${start}&end=${end}&${filter}=${value}`;
    },
    getChartData: (suitId, start, end, filter, value) => {
      return !filter
        ? `${API_HOST}/mobile_attributes_charts/${suitId}?start=${start}&end=${end}`
        : `${API_HOST}/mobile_attributes_charts/${suitId}?start=${start}&end=${end}&${filter}=${value}`;
    },
    getTableData: (suitId, start, end, filter, value) => {
      return !filter
        ? `${API_HOST}/mobile_attributes_all_stats/${suitId}?start=${start}&end=${end}`
        : `${API_HOST}/mobile_attributes_all_stats/${suitId}?start=${start}&end=${end}&${filter}=${value}`;
    },
  },
  UNINSTALL_MONITOR: {
    getData: (serverURL, suitId, apiKey, start, end) =>
      `${serverURL}/functions/uninstallMonitorAnalytics?projectId=${suitId}&apiKey=${apiKey}&fromDate=${start}&toDate=${end}&monthsLimit=12`,
    followUpUsers: (serverURL, suitId, apiKey, start, end) =>
      `${serverURL}/functions/uninstallMonitorFollowUp?projectId=${suitId}&apiKey=${apiKey}&fromDate=${start}&toDate=${end}`,
    cleanUpUsers: (serverURL, suitId, apiKey, email) =>
      `${serverURL}/functions/uninstallMonitorCleanUp?projectId=${suitId}&apiKey=${apiKey}&email=${email}`,
  },
  APP_PUSH: {
    SEND_APP_PUSH: (serverUrl) => `${serverUrl}`,
  },
  UPLOAD: {
    upload: (suitId) =>
      suitId ? `${API_HOST}/${suitId}/upload` : `${API_HOST}/upload`,
  },
  WHATSAPP: {
    getWhatsAppTemplates: (suitId) =>
      `${NOTIFY_HOST}/${suitId}/whatsapp/templates`,

    getWhatsAppPhoneNumbers: (suitId, wabaId) =>
      `${NOTIFY_HOST}/${suitId}/whatsapp/numbers/${wabaId}`,

    getWhatsAppBussinessAccountIds: (suitId, businessId) =>
      `${NOTIFY_HOST}/${suitId}/whatsapp/waba/${businessId}`,

    listWhatsAppCampaigns: (suitId, page, start, end) =>
      `${NOTIFY_HOST}/${suitId}/campaigns?type=WHATSAPP&page=${page}&period=day&start=${start}&end=${end}`,

    deleteWhatsAppCampaign: (suitId, campaignId) =>
      `${NOTIFY_HOST}/${suitId}/campaigns/${campaignId}`,
  },
  WHATSAPPLITE: {
    listWhatsAppLite: (suitId, page, start, end) =>
      `${NOTIFY_HOST}/${suitId}/campaigns?type=UOWHATSAPP&page=${page}&period=day&start=${start}&end=${end}`,
    deleteWhatsAppLite: (suitId, campaignId) =>
      `${NOTIFY_HOST}/${suitId}/campaigns/${campaignId}`,
  },
  FACEBOOK: {
    generateLongAccessToken: (accessToken) =>
      `${NOTIFY_HOST}/long_token?access_token=${accessToken}`,
    businessAccounts: (accessToken) =>
      `${NOTIFY_HOST}/business_id?access_token=${accessToken}`,
    adAccounts: (accessToken, businessId) =>
      `${NOTIFY_HOST}/get_business?access_token=${accessToken}&business_id=${businessId}`,
  },
  CRM: {
    getAllCrmUsers: () => `${appBackend}/functions/getCRMUsers`,
    getAllCrmUsersByFilter: () => `${appBackend}/functions/CRMFilter`,
  },
  SEGMENT: {
    addSegment: () => `${appBackend}/functions/addSegment`,
    getAllSegments: () => `${appBackend}/functions/getAllSegments`,
    getMinifiedSegments: () => `${appBackend}/functions/getMinifiedSegments`,
    deleteSegment: () => `${appBackend}/functions/deleteSegment`,
    getSegment: (segmentId, subdomain) =>
      `${appBackend}/classes/Segments${subdomain}/${segmentId}`,
    updateSegments: (suitId) => `${ADMIN_SERVER}/update_appsegments/${suitId}`,
  },
  AUTOMATOR: {
    AUTOMESSAGES: (suitId, page) =>
      `${AUTOMATOR_HOST}/automessages/${suitId}${page ? "?page=" + page : ""}`,
    AUTOMESSAGESTRUE: (suitId) =>
      `${AUTOMATOR_HOST}/automessages/${suitId}?automation=true`,

    AUTOMESSAGE: (suitId, automessageId) =>
      `${AUTOMATOR_HOST}/automessages/${suitId}/${automessageId}`,

    ANALYTICS: (suitId, automessageId) =>
      `${AUTOMATOR_HOST}/automessages/${suitId}/analytics/${automessageId}`,

    BULK_DELETE: (suitId) => `${AUTOMATOR_HOST}/automessages/${suitId}/bulk`,

    PREBUILT_AUTOMATORS: (suitId) =>
      `${ADMIN_SERVER}/outsource/automations/${suitId}`,
    INVOCATIONS: (suitId, apiKey, data) =>
      `${servURLInvocations}/${suitId}/campaigns?getAutomessage=true&start=${data.start}&end=${data.end}`,
  },
  PURCHASES: {
    GET_PURCHASES: () => `${appBackend}/functions/getPurchases`,
    GET_FILTERED_PURCHASES: () =>
      `${appBackend}/functions/getFilteredPurchases`,
  },
  SHRINKIT_V3: {
    ENABLE_OTP: (suitId) => `${SHOPIFY_API}otp/enable/${suitId}`,
    DISABLE_OTP: (suitId) => `${SHOPIFY_API}otp/disable/${suitId}`,
    ENABLE_V3: (suitId) => `${SHOPIFY_API}v3/enable/${suitId}`,
    DISABLE_V3: (suitId) => `${SHOPIFY_API}v3/disable/${suitId}`,
  },
  ABANDONEDCART: {
    GET_NOTIFY_LOGS,
  },
};

export default API_ENDPOINTS;
