import { getSuitBySubdomain } from "modules/user";
import { v4 as uuidv4 } from "uuid";
import productsNames from "../../consts/productsNames";
import { productExists } from "../utils";

const generateProductRoutes = (products) => {
  if (!products) return [];

  const hasAppBackend = productExists(products)(productsNames.appBackend);
  const hasMicroPage = productExists(products)(
    productsNames.mobileLandingPages
  );
  const hasWebPush = productExists(products)(productsNames.webPushNotification);
  const hasWhatsApp = productExists(products)(productsNames.whatsApp);
  const hasWhatsAppLite = productExists(products)(
    productsNames.unofficialWhatsApp
  );
  const hasSMS = true;
  const hasEmail = productExists(products)(productsNames.email);
  const hasAutomator = productExists(products)(productsNames.automator);
  const hasCRM = productExists(products)(productsNames.richUserProfile);

  let subdomain = window.location.pathname.split("/app/")[1];
  if (subdomain.includes("/")) subdomain = subdomain.split("/")[0];

  const currentSuit = getSuitBySubdomain(subdomain);

  const isMobileMarketting = currentSuit.type === "mobile_marketing";
  const isShopify = currentSuit?.source?.toLowerCase() === "shopify";
  const isZid = currentSuit?.source?.toLowerCase() === "zid";
  const isSalla = currentSuit?.source?.toLowerCase() === "salla";

  const campaignChildren = [];
  const crmChildren = [];

  const createMenuItem = ({
    slug,
    title,
    url,
    children = [],
    icon,
    subMenuLevel = 0,
    icon2,
  }) => {
    const item = {
      key: uuidv4(),
      slug,
      title,
      subMenuLevel: subMenuLevel > 0 ? subMenuLevel : undefined,
      icon,
      children: children.length ? children : undefined,
      icon2,
    };

    if (!children.length) {
      item.url = url;
    }

    return item;
  };

  if (hasCRM) {
    crmChildren.push(
      createMenuItem({
        slug: "CRM",
        title: "customers",
        url: "/app/crm",
        subMenuLevel: 1,
      }),
      createMenuItem({
        slug: "segments",
        title: "Segments",
        url: "/app/segments",
        subMenuLevel: 1,
      }),
      createMenuItem({
        slug: "subscribers",
        title: "Subscribers",
        url: "/app/subscribers",
        subMenuLevel: 1,
      })
    );
  } else {
    crmChildren.push(
      createMenuItem({
        slug: "CRM",
        title: "customers",
        url: "/app/crm",
        icon: "fa fa-lock",
        subMenuLevel: 1,
      }),
      createMenuItem({
        slug: "segments",
        title: "Segments",
        url: "/app/segments",
        icon: "fa fa-lock",
        subMenuLevel: 1,
      }),
      createMenuItem({
        slug: "subscribers",
        title: "Subscribers",
        url: "/app/subscribers",
        subMenuLevel: 1,
      })
    );
  }

  if (hasWebPush) {
    campaignChildren.push(
      createMenuItem({
        slug: "pushCampaigns",
        title: "Push Campaigns",
        url: "/app/listWebPushCampaigns",
        subMenuLevel: 1,
      })
    );
  } else {
    campaignChildren.push(
      createMenuItem({
        slug: "pushCampaigns",
        title: "Push Campaigns",
        url: "/app/listWebPushCampaigns",
        icon: "fa fa-lock",
        subMenuLevel: 1,
      })
    );
  }

  if (hasEmail) {
    campaignChildren.push(
      createMenuItem({
        slug: "emailCampaigns",
        title: "Email Campaigns",
        url: "/app/email/list",
        subMenuLevel: 1,
      })
    );
  } else {
    campaignChildren.push(
      createMenuItem({
        slug: "emailCampaigns",
        title: "Email Campaigns",
        url: "/app/email/list",
        icon: "fa fa-lock",
        subMenuLevel: 1,
      })
    );
  }

  if (hasWhatsApp) {
    campaignChildren.push(
      createMenuItem({
        slug: "whatsappCampaigns",
        title: "WhatsApp Campaigns",
        url: "/app/whatsapp/list",
        subMenuLevel: 1,
      })
    );
  } else {
    campaignChildren.push(
      createMenuItem({
        slug: "whatsappCampaigns",
        title: "WhatsApp Campaigns",
        url: "/app/whatsapp/list",
        icon: "fa fa-lock",
        subMenuLevel: 1,
      })
    );
  }
  if (hasWhatsAppLite) {
    campaignChildren.push(
      createMenuItem({
        slug: "whatsappLite",
        title: "WhatsApp Lite",
        url: "/app/whatsappLite/list",
        subMenuLevel: 1,
      })
    );
  } else {
    campaignChildren.push(
      createMenuItem({
        slug: "whatsappLite",
        title: "WhatsApp Lite",
        url: "/app/whatsappLite/list",
        icon: "fa fa-lock",
        subMenuLevel: 1,
      })
    );
  }

  if (hasSMS && !isSalla) {
    campaignChildren.push(
      createMenuItem({
        slug: "smsCampaigns",
        title: "SMS Campaigns",
        url: "/app/listSMSCampaigns",
        subMenuLevel: 1,
      })
    );
  } else if (!isSalla) {
    campaignChildren.push(
      createMenuItem({
        slug: "smsCampaigns",
        title: "SMS Campaigns",
        url: "/app/listSMSCampaigns",
        icon: "fa fa-lock",
        subMenuLevel: 1,
      })
    );
  }

  const items = [
    createMenuItem({
      slug: "home",
      title: "Home",
      url: "/app/home",
      icon: "fa fa-home fa-lg",
    }),

    hasCRM
      ? createMenuItem({
          slug: "purchases",
          title: "Purchases",
          url: "/app/purchases",
          icon: "fa fa-shopping-cart fa-lg",
        })
      : createMenuItem({
          slug: "purchases",
          title: "Purchases",
          url: "/app/purchases",
          icon: "fa fa-shopping-cart fa-lg",
          icon2: "fa fa-lock",
        }),

    (isShopify || isZid || isSalla) &&
      createMenuItem({
        slug: "products",
        title: "Products",
        url: "/app/products",
        icon: "fa fa-list",
      }),

    createMenuItem({
      slug: "CRM",
      title: "CRM",
      children: crmChildren,
      icon: "fa fa-briefcase fa-lg",
    }),

    createMenuItem({
      slug: "CampaignsHeader",
      title: "Campaigns",
      children: campaignChildren,
      icon: "fa fa-bullhorn fa-lg",
    }),

    hasAutomator
      ? createMenuItem({
          slug: "automator",
          title: "Automated",
          url: "/app/automator",
          icon: "fa fa-cogs fa-lg",
        })
      : createMenuItem({
          slug: "automator",
          title: "Automated",
          url: "/app/automator",
          icon: "fa fa-cogs fa-lg",
          icon2: "fa fa-lock",
        }),

    createMenuItem({
      slug: "templates",
      title: "Templates",
      url: "/app/templates",
      icon: "fa fa-file-text-o fa-lg",
    }),

    createMenuItem({
      slug: "shortLinks",
      title: "Short Links",
      url: "/app/shortLinks",
      icon: "fa fa-link fa-lg",
    }),
  ];

  if (hasMicroPage) {
    campaignChildren.push(
      createMenuItem({
        slug: "microPages",
        title: "Micro Pages",
        url: "/app/microPages",
        subMenuLevel: 1,
      })
    );
  } else {
    campaignChildren.push(
      createMenuItem({
        slug: "microPages",
        title: " Promotion Pages",
        url: "/app/microPages",
        icon: "fa fa-lock",
        subMenuLevel: 1,
      })
    );
  }

  if (hasSMS && !isSalla) {
    crmChildren.push(
      createMenuItem({
        slug: "smsTargetLists",
        title: "SMS Target Lists",
        url: "/app/smsTargetLists",
        subMenuLevel: 1,
      })
    );
  } else if (!isSalla) {
    crmChildren.push(
      createMenuItem({
        slug: "smsTargetLists",
        title: "SMS Target Lists",
        url: "/app/smsTargetLists",
        icon: "fa fa-lock",
        subMenuLevel: 1,
      })
    );
  }
  crmChildren.push(
    createMenuItem({
      slug: "notificationLogs",
      title: "Notification Logs",
      url: "/app/notificationLogs",
      subMenuLevel: 1,
    })
  );

  items.push(
    createMenuItem({
      slug: "store",
      title: "Stores",
      url: "/stores",
      icon: "fa fa-database fa-lg",
    })
  );
  // items.push(
  //   createMenuItem({
  //     slug: "abandonedCart",
  //     title: "Abandoned Cart",
  //     url: "/app/abandonedCart",
  //     // icon: "fa fa-database fa-lg",
  //   })
  // );

  let user = JSON.parse(localStorage.getItem("user.data"));
  if ((hasAppBackend || isMobileMarketting) && !user.projects_products) {
    items.push(
      createMenuItem({
        slug: "setting",
        title: "Store Settings",
        url: "/app/settings",
        icon: "fa fa-gear fa-lg",
      })
    );
  }

  return items.filter((item) => item);
};

export default generateProductRoutes;
